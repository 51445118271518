exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); /* #E5F0FF */\r\n/* #E5F0FF */\r\n/* #E9F1FC */\r\n/* #D9E3F2 */\r\n/* 90% transparent version of motion-primary */\r\n/* #FFFFFF */\r\n/* 25% transparent version of ui-white */\r\n/* 25% transparent version of ui-white */\r\n/* 25% transparent version of ui-white */\r\n/* 15% transparent version of black */\r\n/* #575E75 */\r\n/*$motion-primary: hsla(215, 100%, 65%, 1);  #4C97FF */\r\n/* #293289 */\r\n/*$motion-primary: hsla(18.9,100%,49.8%, 1);  #FE5000 */\r\n/* #3373CC */\r\n/* 35% transparent version of motion-primary */\r\n/* 15% transparent version of motion-primary */\r\n/* #FF661A */\r\n/* #E64D00 */\r\n/* #CF63CF */\r\n/* #BD42BD */\r\n/* #FFAB19 */\r\n/* #FF8C1A */\r\n/* #0FBD8C */\r\n/* #0FBD8C */\r\n/* #FF8C1A */\r\n/* #FFB366 */\r\n/* #FF8C1A */\r\n/* #0FBD8C */\r\n/* #0B8E69 */\r\n/* 35% transparent version of extensions-primary */\r\n/* opaque version of extensions-transparent, on white bg */\r\n/* lighter than motion-primary */\r\n.turbo-mode_turbo-container_1qQFO {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    padding: 0.25rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n}\r\n.turbo-mode_turbo-icon_A_vJA {\r\n    margin: 0.25rem;\r\n}\r\n.turbo-mode_turbo-label_1v23d {\r\n    font-size: 0.625rem;\r\n    font-weight: bold;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    color: hsla(38, 100%, 55%, 1);\r\n    white-space: nowrap;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"turbo-container": "turbo-mode_turbo-container_1qQFO",
	"turboContainer": "turbo-mode_turbo-container_1qQFO",
	"turbo-icon": "turbo-mode_turbo-icon_A_vJA",
	"turboIcon": "turbo-mode_turbo-icon_A_vJA",
	"turbo-label": "turbo-mode_turbo-label_1v23d",
	"turboLabel": "turbo-mode_turbo-label_1v23d"
};