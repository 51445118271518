exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); /* #E5F0FF */\r\n/* #E5F0FF */\r\n/* #E9F1FC */\r\n/* #D9E3F2 */\r\n/* 90% transparent version of motion-primary */\r\n/* #FFFFFF */\r\n/* 25% transparent version of ui-white */\r\n/* 25% transparent version of ui-white */\r\n/* 25% transparent version of ui-white */\r\n/* 15% transparent version of black */\r\n/* #575E75 */\r\n/*$motion-primary: hsla(215, 100%, 65%, 1);  #4C97FF */\r\n/* #293289 */\r\n/*$motion-primary: hsla(18.9,100%,49.8%, 1);  #FE5000 */\r\n/* #3373CC */\r\n/* 35% transparent version of motion-primary */\r\n/* 15% transparent version of motion-primary */\r\n/* #FF661A */\r\n/* #E64D00 */\r\n/* #CF63CF */\r\n/* #BD42BD */\r\n/* #FFAB19 */\r\n/* #FF8C1A */\r\n/* #0FBD8C */\r\n/* #0FBD8C */\r\n/* #FF8C1A */\r\n/* #FFB366 */\r\n/* #FF8C1A */\r\n/* #0FBD8C */\r\n/* #0B8E69 */\r\n/* 35% transparent version of extensions-primary */\r\n/* opaque version of extensions-transparent, on white bg */\r\n/* lighter than motion-primary */\r\n.meter_green_1TDyl {\r\n    fill: rgb(171, 220, 170);\r\n    stroke: rgb(174, 211, 168);\r\n}\r\n.meter_yellow_YVGHr {\r\n    fill: rgb(251, 219, 130);\r\n    stroke: rgb(239, 212, 134);\r\n}\r\n.meter_red_S3fdX {\r\n    fill: rgb(251, 194, 142);\r\n    stroke: rgb(235, 189, 142);\r\n}\r\n.meter_mask-container_m3uVt {\r\n    position: relative;\r\n}\r\n.meter_mask_1s9vE {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    height: 100%;\r\n    width: 100%;\r\n    -webkit-transform-origin: top;\r\n        -ms-transform-origin: top;\r\n            transform-origin: top;\r\n    will-change: transform;\r\n    background: hsla(215, 100%, 95%, 1);\r\n    opacity: 0.75;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"green": "meter_green_1TDyl",
	"yellow": "meter_yellow_YVGHr",
	"red": "meter_red_S3fdX",
	"mask-container": "meter_mask-container_m3uVt",
	"maskContainer": "meter_mask-container_m3uVt",
	"mask": "meter_mask_1s9vE"
};