exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); /* #E5F0FF */\r\n/* #E5F0FF */\r\n/* #E9F1FC */\r\n/* #D9E3F2 */\r\n/* 90% transparent version of motion-primary */\r\n/* #FFFFFF */\r\n/* 25% transparent version of ui-white */\r\n/* 25% transparent version of ui-white */\r\n/* 25% transparent version of ui-white */\r\n/* 15% transparent version of black */\r\n/* #575E75 */\r\n/*$motion-primary: hsla(215, 100%, 65%, 1);  #4C97FF */\r\n/* #293289 */\r\n/*$motion-primary: hsla(18.9,100%,49.8%, 1);  #FE5000 */\r\n/* #3373CC */\r\n/* 35% transparent version of motion-primary */\r\n/* 15% transparent version of motion-primary */\r\n/* #FF661A */\r\n/* #E64D00 */\r\n/* #CF63CF */\r\n/* #BD42BD */\r\n/* #FFAB19 */\r\n/* #FF8C1A */\r\n/* #0FBD8C */\r\n/* #0FBD8C */\r\n/* #FF8C1A */\r\n/* #FFB366 */\r\n/* #FF8C1A */\r\n/* #0FBD8C */\r\n/* #0B8E69 */\r\n/* 35% transparent version of extensions-primary */\r\n/* opaque version of extensions-transparent, on white bg */\r\n/* lighter than motion-primary */\r\n/* make sure to keep these in sync with other constants,\r\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\r\n/* layout contants from `layout-constants.js` */\r\n.user-avatar_user-thumbnail_IwOnc {\r\n    width: 2rem;\r\n    height: 2rem;\r\n    border-radius: calc(0.5rem / 2);\r\n    vertical-align: middle;\r\n    -webkit-box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.15);\r\n            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.15);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"user-thumbnail": "user-avatar_user-thumbnail_IwOnc",
	"userThumbnail": "user-avatar_user-thumbnail_IwOnc"
};